/* eslint-disable no-undef, no-unused-expressions */

'use strict';

const customerHasNotEnteredEarlyAccess = document.querySelector('#customer-has-not-entered-early-access');
const customerHasEnteredEarlyAccess = document.querySelector('#customer-has-entered-early-access');
const earlyAccessButton = document.querySelector('#early-access-button');
const energyLaunchAddToCartButton = document.querySelector('.energy-launch-add-to-cart');
const rowAttribute = document.querySelector('.attributes .row.dyanamic-attr');
const shipInStore = document.querySelector('.dyanamic-product-availability.ship-instore');

/**
* Updates rendering the correct button on load, or during a variant selection on the energy launch pdp
*/
const handleButtonRenderingOnEnergyLaunchPDP = () => {
    const launchEventContainer = document.querySelector('.launch-event-date');
    const currentDateTimeUTC = new Date().toISOString();

    if (launchEventContainer) {
        const launchEventStartTimeUtc = launchEventContainer.dataset.launchEventStartTimeUtc;
        const nextLaunchEvent = launchEventContainer.dataset.nextLaunchEvent;
        const notifyMeButton = document.querySelector('.container.product-detail .cart-and-ipay button.notify-cart');

        if (nextLaunchEvent === null) {
            energyLaunchAddToCartButton.classList.add('d-none');
            notifyMeButton.classList.remove('d-none');
            notifyMeButton.dataset.containerType = 'out-of-stock-notify';
            shipInStore.classList.add('d-none');
            return;
        }

        if (launchEventStartTimeUtc > currentDateTimeUTC) {
            // ongoing event
            energyLaunchAddToCartButton.classList.remove('d-none');
            notifyMeButton.classList.add('d-none');
            rowAttribute.classList.remove('d-none');
            if (shipInStore) shipInStore.classList.remove('d-none');
        } else {
            energyLaunchAddToCartButton.classList.add('d-none');
            notifyMeButton.classList.remove('d-none');
            notifyMeButton.dataset.containerType = 'not-available-yet';
            rowAttribute.classList.add('d-none');
            if (shipInStore) shipInStore.classList.add('d-none');
        }
    }
};

/**
* Determines what text is shown to the customer
*/
const handleCustomerHasEnteredEarlyAccessText = () => {
    const successfullyEnteredButton = document.querySelector('#early-access-successfully-entered-button');

    if (successfullyEnteredButton && customerHasNotEnteredEarlyAccess) {
        customerHasEnteredEarlyAccess.classList.remove('d-none');
        customerHasEnteredEarlyAccess.classList.add('d-block');
        customerHasNotEnteredEarlyAccess.remove();
    }
};

/**
* Require age verification before allowing a consumer to login for early access
*/
const handleAgeVerificationCheckboxClick = () => {
    const ageVerificationCheckbox = document.querySelector('#age-verification-checkbox');

    if (ageVerificationCheckbox) {
        ageVerificationCheckbox.addEventListener('click', () => {
            ageVerificationCheckbox.checked ? earlyAccessButton.classList.remove('disabled') : earlyAccessButton.classList.add('disabled');
        });
    }
};

/**
* Sets up early access entry button
*/
const handleEarlyAccessButtonClick = () => {
    let sendResponseToAPI = (event) => {
        event.preventDefault();
        if (earlyAccessButton) {
            let url = earlyAccessButton.attributes['data-access-event'].value;
            let launchEventId = earlyAccessButton.attributes['data-launch-event-id'].value;
            // hide error message if it was previously shown
            $('.early-access-error')
                .addClass('d-none')
                .find('.error-id')
                .text('');

            $.spinner().start();
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: {
                    launchEventId: launchEventId
                },
                success: function () {
                    const earlyAccessButtonEntered = document.querySelector('#early-access-entered-button');
                    const ageVerificationCheckboxWrapper = document.querySelector('#age-verification-wrapper');

                    earlyAccessButton.remove();
                    ageVerificationCheckboxWrapper.remove();
                    $('.official-rules-link').removeClass('d-none');
                    if (customerHasEnteredEarlyAccess && customerHasNotEnteredEarlyAccess) {
                        customerHasNotEnteredEarlyAccess.classList.add('d-none');
                        customerHasEnteredEarlyAccess.classList.remove('d-none');
                        customerHasEnteredEarlyAccess.classList.add('d-block');
                    }

                    if (earlyAccessButtonEntered) {
                        earlyAccessButtonEntered.classList.remove('d-none');
                        earlyAccessButtonEntered.classList.add('d-block');
                    }
                },
                error: function (err) {
                    // show error message with correlation id
                    $('.early-access-error')
                        .removeClass('d-none')
                        .find('.error-id')
                        .text(err.responseJSON.correlationId);
                },
                complete: function () {
                    $.spinner().stop();
                }
            });
        }
    };

    if (earlyAccessButton) {
        $(earlyAccessButton).off('click');
        $(earlyAccessButton).on('click', sendResponseToAPI);
    }
};

/**
 * Converts the integer value of early access passes to a word
 * @param {number} number - int value representing the number of passes
 * @returns {string} returns the number spelled out as a word
 */
const convertNumberToWord = (number) => {
    var singleDigit = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    var doubleDigit = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    var belowHundred = ['Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

    if (number < 0) return false;
    if (number === 0) return 'Zero';

    /**
    * Takes a number as an int value and returns the number spelled out
    * @param {number} num - int value representing the number of passes
    * @returns {string} spelled out number of passes
    */
    function translate(num) {
        var word = '';
        if (num < 10) {
            word = singleDigit[num];
        } else if (num < 20) {
            word = doubleDigit[num - 10];
        } else if (num < 100) {
            var rem = translate(num % 10);
            word = belowHundred[(num - (num % 10)) / 10 - 2] + ' ' + rem;
        } else if (num < 1000) {
            word = singleDigit[Math.trunc(num / 100)] + ' Hundred ' + translate(num % 100);
        } else if (num < 1000000) {
            word = translate(parseInt(num / 1000, 10)).trim() + ' Thousand ' + translate(num % 1000);
        }

        return word;
    }
    var result = translate(number);
    result.trim();

    return result;
};

/**
 * Show or hide the input for order number based on select.
 */
const updateEarlyAccessPassNumber = () => {
    var $earlyAccessPassesValue = $('#early-access-passes-value');
    if ($earlyAccessPassesValue.length > 0) {
        var earlyAccessPassesWord = convertNumberToWord($earlyAccessPassesValue.text());
        $('#early-access-passes').text(earlyAccessPassesWord + ' (' + $earlyAccessPassesValue.text() + ')');
    }
};

const showFooterLinks = () => {
    const howEarlyAccessWorksLink = document.querySelector('#how-early-access-works-link');
    const termsConditionsLink = document.querySelector('#tc-early-access');

    if (termsConditionsLink) {
        termsConditionsLink.classList.remove('d-none');
    }

    if (howEarlyAccessWorksLink) {
        howEarlyAccessWorksLink.classList.remove('d-none');
    }
};

// Immediately Invoked Functional Expression to make these functions available at load time
(() => {
    handleAgeVerificationCheckboxClick();
    handleButtonRenderingOnEnergyLaunchPDP();
    handleCustomerHasEnteredEarlyAccessText();
    handleEarlyAccessButtonClick();
    showFooterLinks();
    updateEarlyAccessPassNumber();
})();

// We are exporting out the same function thats in the IIFE above, as it is needed in a separate file
module.exports = {
    handleButtonRenderingOnEnergyLaunchPDP: handleButtonRenderingOnEnergyLaunchPDP
};
